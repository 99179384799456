<template>
  <div class="livelist" style="background: #f5f5f5" v-loading="loading">
    <Tabs></Tabs>
    <!-- 导航 -->
    <div id="nav_bar_box">
      <ul>
        <li>
          <div
            class="nav-item"
            :class="[
              getdata.two_chaptercate_id == item.id ? 'nav_name_active' : '',
            ]"
            v-for="item in navBar"
            :key="item.id"
            @click="change(item)"
          >
            <!-- <div :class="{'nav_aaa':item.id!=index}" @click="tocolor(index)"> -->
            {{ item.name }}
            <!-- </div> -->
          </div>
        </li>
      </ul>
    </div>
    <div class="check">
      <div class="l">
        <span
          :class="getdata.user_limit == '' ? 'act' : ''"
          @click="isallchange('')"
        >
          全部
        </span>
        <span
          :class="getdata.user_limit == 2 ? 'act' : ''"
          @click="isallchange(2)"
        >
          免费直播
        </span>
        <span
          :class="getdata.user_limit == 1 ? 'act' : ''"
          @click="isallchange(1)"
        >
          VIP直播
        </span>
      </div>
      <div class="r">
        <el-checkbox-group v-model="live_status">
          <el-checkbox label="1">直播中</el-checkbox>
          <el-checkbox label="2">即将开始</el-checkbox>
          <el-checkbox label="3">已结束</el-checkbox>
        </el-checkbox-group>
      </div>
    </div>
    <!-- 没有直播 -->
    <div class="no_class zbk" v-if="list.length == 0">
      <img src="../../assets/img/zhobo.png" alt />
      <p>
        直播老师正在准备中，直播上线将会提前推送给您哦~～
        <br />
      </p>
    </div>
    <div v-else class="live_content">
      <div class="live_card" v-for="(a, i) in list" :key="a.cate_name + i">
        <div :class="a.user_limit == 1 ? 'livelimetm' : 'livelimetv'">
          {{ a.user_limit == 1 ? "VIP" : "免费" }}
        </div>
        <div class="l">
          <div class="l_l">
            <img :src="a.live_images" alt="" />
            <!-- <el-image :src="a.live_images" fit="cover"></el-image> -->
            <div class="hovermask" v-if="a.n == 1 || a.n == 2">
              <span
                v-if="a.n == 1"
                style="margin-bottom: 10px"
                @click="_live_detail(a.live_id)"
              >
                <img src="../../assets/img/zhibozhong.png" alt="" /> 进入直播
              </span>
              <span
                v-if="a.n == 1 || a.n == 2"
                style="background: #b5966c"
                @click="handout(a)"
              >
                <img src="../../assets/img/xiazaijiangyi.png" alt="" /> 下载讲义
              </span>
            </div>
          </div>
          <div class="l_r">
            <h2>{{ a.live_title }}</h2>
            <div class="s_">
              <span>
                <img src="../../assets/img/kemu_live.png" alt />
                科目：{{ a.cate_name }}
              </span>
              <span>
                <img src="../../assets/img/timelive.png" alt />
                开始时间：{{ a.start_date }}
              </span>
            </div>
            <div class="_b">
              <div class="b_">
                <div class="img">
                  <el-image
                    style="width: 100%; height: 100%"
                    :src="a.teacher_image"
                    fit="cover"
                  >
                  </el-image>
                </div>
                {{ a.teacher_name }}
              </div>
              <div :class="a.n == 1 ? 'z' : a.n == 2 ? 'j' : 'e'" type="button">
                <img
                  :src="
                    a.n == 1
                      ? require('../../assets/img/zhibozhong.png')
                      : a.n == 2
                      ? require('../../assets/img/jijiang.png')
                      : require('../../assets/img/jieshu.png')
                  "
                  alt
                />
                {{ a.n == 1 ? "直播中" : a.n == 2 ? "即将开始" : "直播结束" }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="!nomore && list.length > 0" class="more" @click="add">
      查看更多 <img src="../../assets/img/jts.png" alt="" />
    </div>
    <div v-show="nomore && list.length > 0" class="more">没有更多了~</div>

    <el-dialog
      title="提示"
      :visible.sync="centerDialogVisible"
      :show-close="false"
      width="734px"
      custom-class="dailogboxs"
      center
    >
      <div slot="title" class="dialog-tit">
        <span>直播讲义</span>
        <span>{{ handouttit }}</span>
        <img
          style="width: 20px; height: 20px; cursor: pointer"
          src="../../assets/img/classclose.png"
          alt=""
          @click="centerDialogVisible = false"
        />
      </div>
      <div class="handoutbody">
        <ul v-if="handoutlist.length > 0">
          <li v-for="item in handoutlist" :key="item.live_book_id">
            <div class="l">
              <img :src="imgsrc(item.title)" alt="" />
              <h2>{{ item.title }}</h2>
              <span>文件大小：{{ item.format_size }}</span>
            </div>
            <div class="r">
              <a :href="item.url" target="_blank" rel="noopener noreferrer">
                查看</a
              >
            </div>
          </li>
        </ul>
        <div v-else style="text-align: center">
          <img
            style="width: 380px; margin-top: 67px"
            src="../../assets/img/nojiangyi.png"
            alt=""
          />
        </div>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisible = false"
          >确 定</el-button
        >
      </span> -->
    </el-dialog>
    <Foot></Foot>
  </div>
</template>
<script>
import api from "@/api/api";
export default {
  name: "Livelist",
  data() {
    return {
      centerDialogVisible: false,
      handouttit: "",
      handoutlist: [],
      nomore: false,
      loading: true,
      list: [],
      live_status: [],
      getdata: {
        two_chaptercate_id: 29,
        user_limit: "",
        page: 1,
      },
      navBar: [
        {
          id: 19,
          name: "一级建造师",
        },
        {
          id: 29,
          name: "二级建造师",
        },
        {
          id: 43,
          name: "中级注册安全工程师",
        },
        {
          id: 141,
          name: "监理工程师",
        },
      ],
    };
  },
  watch: {
    live_status(a, b) {
      this.getdata.page = 1;
      this.list = [];
      this.nomore = false;
      this.getlist();
    },
  },
  methods: {
    imgsrc(e) {
      let a = e.split(".");
      let b = a[a.length - 1];
      let c = [
        "txt",
        "zip",
        "xlsx",
        "xls",
        "tiff",
        "tar",
        "rar",
        "ppt",
        "png",
        "md",
        "jpg",
        "jpeg",
        "gz",
        "docx",
        "doc",
        "csv",
        "bz2",
        "bmp",
      ].indexOf(b);
      if (c > -1) {
        return require(`../../assets/img/${b}.png`);
      } else {
        return require(`../../assets/img/tongyong.png`);
      }
      // console.log(a[a.length - 1])
    },
    handout(e) {
      console.log(e);
      this.handouttit = e.live_title;
      api
        .live_file_book({
          live_file_id: e.live_field_id,
          page: 1,
          page_size: 100,
        })
        .then((res) => {
          if (res.data.code == 1) {
            this.handoutlist = res.data.data.list;
            this.centerDialogVisible = true;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          // this.$message.error{}
        });
    },
    add() {
      if (!this.nomore) {
        this.getdata.page = this.getdata.page + 1;
        this.getlist();
      }
    },
    //直播详情
    _live_detail(val) {
      let data = {
        live_id: val,
      };
      api.live_detail(data).then((res) => {
        //  console.log(res)
        if (res.data.code == 1) {
          //  console.log(res.data.data.url)
          window.open(res.data.data.url);
        } else if (parseInt(res.data.code) == 401) {
          this.$message.error({
            message: "请先登录!",
            duration: "2000",
          });
          this.$router.push({
            name: "Login",
          });
        } else if (parseInt(res.data.code) == 0) {
          if (res.data.data.error_code == 4) {
            this.dialogVisible = true;
            this.show_type = 2;
          } else {
            this.$message.error(res.data.msg);
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    isallchange(e) {
      this.getdata.user_limit = e;
      this.getdata.page = 1;
      this.nomore = false;
      this.list = [];
      this.getlist();
    },
    // 导航栏事件
    change(el) {
      this.getdata.two_chaptercate_id = el.id;
      this.getdata.page = 1;
      this.nomore = false;
      this.list = [];
      this.getlist();
    },
    getlist() {
      this.loading = true;
      let data = this.getdata;
      data.live_status = this.live_status.toString();
      api.live_field_list(data).then((res) => {
        console.log(res);
        if (res.data.data.list.length < 8) {
          this.nomore = true;
        }
        this.list = [...this.list, ...res.data.data.list];
        this.loading = false;
      });
    },
  },
  created() {
    this.getlist();
  },
};
</script>
<style scoped lang="scss">
.handoutbody ul li:hover {
  background: #ebf2ff;
  .l {
    h2 {
      color: #3f7dff;
    }
    span {
      color: #3f7dff;
    }
  }
}
.handoutbody ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 8px 21px 8px 12px;
  border-bottom: 1px solid #f2f2f2;
  .l {
    display: flex;
    align-items: center;
    img {
      width: 35px;
    }
    h2 {
      width: 320px;
      font-size: 16px;
      font-weight: 500;
      color: #000b2f;
      margin-left: 10px;
      margin-right: 20px;
    }
    span {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
    }
  }

  .r {
    width: 50px;
    height: 24px;
    background: #3f7dff;
    border-radius: 4px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
    a {
      font-size: 14px;
      color: #ffffff;
    }
  }
}
::v-deep .dailogboxs {
  border-radius: 8px;
}
::v-deep .dailogboxs .el-dialog__body {
  padding: 0 18px;
  overflow-y: auto;
  height: 430px;
}
.dialog-tit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid #f2f2f2;
  span:first-child {
    font-weight: 500;
    font-size: 14px;
  }
  span {
    font-size: 18px;
    font-weight: bold;
    color: #3f7dff;
  }
}
::v-deep .dailogboxs .el-dialog__header {
  padding: 0;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #3e7eff;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #3e7eff;
  border-color: #3e7eff;
}

.more {
  margin: 44px auto 119px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 180px;
  justify-content: center;
  height: 36px;
  line-height: 36px;
  background: #e9ecf0;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  color: #a0a4ab;

  img {
    width: 16px;
    height: 16px;
    margin-left: 9px;
  }
}

.livelimetv {
  width: 44px;
  height: 24px;
  background: linear-gradient(30deg, #e34c1c 0%, #eca462 100%);
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  color: #f8f9fd;
  text-align: center;
  line-height: 24px;
  position: absolute;
  top: 11px;
  left: 11px;
  z-index: 1;
}

.livelimetm {
  width: 44px;
  height: 24px;
  background: linear-gradient(30deg, #e5c14d 0%, #f4df9a 100%);
  border-radius: 4px;
  font-size: 16px;
  font-weight: 800;
  color: #5f3904;
  text-align: center;
  line-height: 1.5;
  position: absolute;
  top: 11px;
  left: 11px;
  z-index: 1;
}

.no_class {
  width: 950px;
  height: 423px;
  margin: 30px auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  overflow: hidden;
  margin-top: 10px;

  img {
    width: 200px;
    // height: 224px;
    margin: 100px 0 16px;
  }

  p {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #999999;
    text-align: center;
  }
}

.zbk {
  height: 417px !important;
}

.check {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  margin: 0 auto;

  .l {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #202b3a;
    line-height: 1;

    span {
      padding-right: 25px;
      border-right: 1px solid #dee0e0;
      margin-left: 25px;
      cursor: pointer;
    }

    .act {
      color: #3e7eff;
    }
  }

  .l span:last-child {
    border: none;
  }

  .l span:first-child {
    margin-left: 0;
  }
}

.live_content .live_card:hover {
  transform: scale(1.05);
  z-index: 9;
  .hovermask {
    opacity: 1 !important;
  }
}

.live_content {
  display: flex;
  flex-wrap: wrap;
  width: 1200px;
  margin: 0 auto 30px;

  .live_card:nth-child(4n) {
    margin-right: 0;
  }

  .live_card {
    transition: all 0.3s;
    width: 285px;
    margin-top: 30px;
    cursor: pointer;
    position: relative;
    background: #ffffff;
    box-shadow: 0px 2px 14px 0px rgba(0, 11, 36, 0.08);
    border-radius: 8px;
    margin-right: 20px;
    overflow: hidden;

    .l {
      .l_l {
        width: 100%;
        height: 178px;
        overflow: hidden;
        position: relative;
        .hovermask {
          opacity: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          transition: all 0.3s;
          top: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.6);
          span {
            width: 120px;
            height: 30px;
            background: #ff6500;
            border-radius: 15px;
            display: flex;
            font-size: 16px;
            font-weight: bold;
            color: #ffffff;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            img {
              width: 20px;
              height: 20px;
            }
          }
        }
        img {
          width: 100%;
          height: 100%;
        }
      }

      .l_r {
        padding: 15px 20px;

        h2 {
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 550;
          color: #000b2f;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          height: 40px;
          line-height: 21px;
          margin-bottom: 10px;
        }

        .s_ {
          font-size: 14px;
          font-weight: 400;
          color: #888888;
          display: flex;
          flex-direction: column;

          span {
            font-size: 14px;
            padding: 5px 0;
            border-radius: 11px;
            margin-right: 10px;

            img {
              width: 12px !important;
              height: 12px !important;
              padding-right: 4px;
              margin: 0 !important;
            }
          }
        }

        ._b {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 10px;
          padding-top: 14px;
          border-top: 1px solid #f5f5f5;
        }

        .b_ {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #666666;
          font-weight: 400;

          .img {
            width: 34px;
            height: 34px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 10px;
          }

          img {
            width: 100%;
            height: 100%;
            margin: 0 !important;
          }
        }
      }
    }
  }

  .z {
    color: #fff;
    font-size: 16px;
    padding: 7px 20px;
    border-radius: 20px;
    background: #ff6500;
    display: flex;
    align-items: center;

    img {
      width: 18px !important;
      height: 18px !important;
      padding-right: 7px !important;
      margin: 0 !important;
    }
  }

  .j {
    color: #fff;
    font-size: 16px;
    padding: 7px 20px;
    border-radius: 20px;
    background: #3e7eff;
    display: flex;
    align-items: center;

    img {
      width: 18px !important;
      height: 18px !important;
      padding-right: 7px !important;
      margin: 0 !important;
    }
  }

  .e {
    color: #999999;
    font-size: 16px;
    padding: 7px 20px;
    border-radius: 20px;
    background: #eeeeee;
    display: flex;
    align-items: center;

    img {
      width: 18px !important;
      height: 18px !important;
      padding-right: 7px !important;
      margin: 0 !important;
    }
  }
}

/* 导航 */

#nav_bar_box {
  width: 1200px;
  background: #fff;
  height: 70px;
  margin: 30px auto 40px;
  border-radius: 6px;
  // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
}

#nav_bar_box ul {
  width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

#nav_bar_box ul li {
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
}

#nav_bar_box ul li div {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  // margin-right: 65px;
  text-align: center;
  padding: 5px 20px;
  cursor: pointer;
}

.nav_name_active {
  color: #fff;
  padding: 7px 20px;
  border-radius: 6px;
  /* background: #3e7eff26; */
  background: #3e7eff;
  border-radius: 20px;
}
</style>